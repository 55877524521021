import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { getCanonicalSlug, productLink, validateQty } from "@components/config/helpers";
import Link from "next/link";

const ProductLinkButton = (props) => {

    const {
        canonical_category_slug,
        section_slug,
        product_slug,
        long_term
    } = props;
    
    const urlLink = getCanonicalSlug({canonical_category_slug, section_slug});

    return (
        <Box 
            className="mb-2 d-flex w-100"
            sx={{
                mt: {
                    xl: 0,
                    lg: 1,
                    xs: 0,
                }
            }}
        >
            <Link className="w-100"  href={productLink(urlLink, product_slug, long_term)}>
                <Button
                    size="small"
                    className="cart-add w-100"
                    color="secondary"
                    variant="contained"
                >
                    Configure Bar
                </Button>
            </Link>
        </Box>
    )
}

export default ProductLinkButton